import Image from 'next/image';

const imageKitLoader = ({ src, width, quality }) => {
    if (src[0] === '/') src = src.slice(1);
    const params = [`w-${width}`];
    if (quality) {
        params.push(`q-${quality}`);
    }
    const paramsString = params.join(',');
    var urlEndpoint = 'https://ik.imagekit.io/mealify';
    if (urlEndpoint[urlEndpoint.length - 1] === '/')
        urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1);
    return `${urlEndpoint}/${src}?tr=${paramsString}`;
};

export default function SingleImage(props) {
    const { name, ratio, maxWidth, className } = props.image;
    return (
        <Image
            loader={imageKitLoader}
            src={name ? name : '/placeholder'}
            sizes={maxWidth}
            width={ratio[0]}
            height={ratio[1]}
            objectFit="cover"
            alt="Sample image"
            className={className}
        />
    );
}
