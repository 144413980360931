import { XIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';

export default function BottomPopup(props) {
    const { children, title, setToggleButton, setToggleWrapper, toggleButton } = props;

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => (document.body.style.overflow = 'unset');
    }, []);

    const closePopup = () => {
        setToggleButton(false);
        setToggleWrapper(false);
    };

    return (
        <div className="h-full w-full rounded-t-md bg-white">
            <div
                className="flex  w-full items-center justify-between py-5 px-7 pb-2 "
                onClick={() => closePopup()}
            >
                <div className="font-medium text-gray-800">{title}</div>
                <XIcon className="h-5 w-5" />
            </div>
            {children}
        </div>
    );
}
