import Link from 'next/link';
import { InformationCircleIcon, ExclamationIcon, XCircleIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/outline';

export default function Alert(props) {
    const { style, children } = props;

    if (style === 'info') {
        return (
            <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon
                            className="h-5 w-5 text-blue-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <div className="text-sm text-blue-700">{children}</div>
                    </div>
                </div>
            </div>
        );
    }

    if (style === 'warning') {
        return (
            <div className="rounded-md border border-yellow-200 bg-yellow-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <div className="text-sm text-yellow-700">{children}</div>
                    </div>
                </div>
            </div>
        );
    }

    if (style === 'success') {
        return (
            <div className="rounded-md border border-green-100  bg-green-50 p-4 ">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <div className="text-sm text-green-700">{children}</div>
                    </div>
                </div>
            </div>
        );
    }

    if (style === 'danger') {
        return (
            <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <div className="text-sm text-red-700">{children}</div>
                    </div>
                </div>
            </div>
        );
    }
}
