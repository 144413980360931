import { AnimatePresence, motion } from 'framer-motion';
import useClickAway from '@/utilities/useClickAway.js';

import { XIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';

import InnerPopup from '@/containers/popup/Inner';

export default function PopupWrapper(props) {
    const { setToggleButton, toggleButton, children, title } = props;
    const { ref, active, toggle } = useClickAway();

    useEffect(() => {
        if (toggleButton) {
            toggle();
            setToggleButton((prev) => !prev);
        }
    }, [toggleButton]);

    return (
        <AnimatePresence>
            {active && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0 }}
                    className="fixed bottom-0 right-0 z-[100] flex h-screen w-full flex-col justify-end bg-black/10 shadow-md "
                >
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2, delay: 0.1 }}
                        exit={{ opacity: 0, y: 30 }}
                        className="h-10/12 max-h-[80%] w-full max-w-lg overflow-y-auto rounded-md bg-white"
                        ref={ref}
                    >
                        <InnerPopup
                            ref={ref}
                            title={title}
                            setToggleButton={setToggleButton}
                            toggleButton={toggleButton}
                            setToggleWrapper={toggle}
                        >
                            {children}
                        </InnerPopup>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
