import React, { useState } from 'react';
import Router from 'next/router';
import Link from 'next/link';

import {
    BookOpenIcon,
    HomeIcon,
    MailIcon,
    MenuIcon,
    QuestionMarkCircleIcon,
    UserIcon,
} from '@heroicons/react/outline';
import PopupWrapper from '@/containers/popup/Wrapper';
import SingleImage from '@/components/media/FillImage';
import Alert from '../card/Alert';
import { TruckIcon, CreditCardIcon, ArchiveIcon } from '@heroicons/react/outline';

export default function Header(props) {
    const { focus } = props;

    const [toggle, setToggle] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);

    return (
        <div className="sticky top-0 z-[55] bg-white px-7" id="header">
            <div className="flex h-12 items-center justify-between md:h-16 ">
                <div className="w-20 md:hidden md:w-24">
                    <div className="" onClick={() => setMenuToggle((prev) => !prev)}>
                        <MenuIcon className=" h-5 w-5" />
                    </div>
                </div>

                <div className="flex-none">
                    <Link href={'/'}>
                        <div className="mr-2 w-32 lg:-ml-1.5">
                            <SingleImage
                                image={{
                                    name: 'new-website-copy-2_6wRFbXCN_.png',
                                    ratio: [14, 2],
                                    maxWidth: '100vw',
                                    className: 'flex-none ',
                                }}
                            />
                        </div>
                    </Link>
                </div>

                <span className="flex w-20 items-center justify-end rounded-full py-1 text-right text-xs font-medium md:w-full">
                    <div className="mr-5 hidden space-x-5 text-base md:flex">
                        <Link href="/">
                            <a>Home</a>
                        </Link>
                        <Link href="/menu">
                            <a>Menu</a>
                        </Link>
                        <Link href="/services">
                            <a>Services</a>
                        </Link>
                        <Link href="/faq">
                            <a>FAQ</a>
                        </Link>
                    </div>

                    <Link href="/dashboard">
                        <a>
                            <UserIcon className="ml-2 h-5 w-5" />
                        </a>
                    </Link>
                </span>
            </div>
            <PopupWrapper
                toggleButton={menuToggle}
                setToggleButton={setMenuToggle}
                title="Main Menu"
            >
                <div className=" rounded-l-md bg-white p-7 pt-4 pb-8">
                    <div className="grid grid-cols-2 pb-6 text-gray-900">
                        <Link href="/menu">
                            <a className="flex flex-row items-center py-2">
                                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-100">
                                    <BookOpenIcon className="h-5 w-5 text-gray-700 " />
                                </div>
                                <p className="ml-3 text-center text-base font-medium">Menu</p>
                            </a>
                        </Link>
                        <Link href="/faq">
                            <a className="flex flex-row items-center py-2">
                                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-100">
                                    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-700" />
                                </div>
                                <p className="ml-3 text-center text-base font-medium">FAQ</p>
                            </a>
                        </Link>{' '}
                        <Link href="/dashboard">
                            <a className="flex flex-row items-center py-2">
                                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-100">
                                    <UserIcon className="h-5 w-5 text-gray-700 " />
                                </div>
                                <p className="ml-3 text-center text-base font-medium">Dashboard</p>
                            </a>
                        </Link>
                        <Link href="/">
                            <a className="flex flex-row items-center py-2">
                                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-100">
                                    <HomeIcon className="h-5 w-5 text-gray-700 " />
                                </div>
                                <p className="ml-3 text-center text-base font-medium">Home</p>
                            </a>
                        </Link>
                    </div>
                    <div className="mt-2 flex flex-row space-x-8 px-2 text-lg font-medium">
                        <a
                            href="mailto:help@parallelmeals.ie"
                            target="_blank"
                            className=""
                            rel="noreferrer"
                        >
                            <MailIcon className="h-5 w-5 text-gray-700" />
                        </a>
                        <a
                            href="https://www.instagram.com/parallelmeals/"
                            target="_blank"
                            className=""
                            rel="noreferrer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-gray-700 "
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                            </svg>
                        </a>
                        <a
                            target="_blank"
                            href="https://www.facebook.com/parallelmeals/"
                            className=""
                            rel="noreferrer"
                        >
                            <svg
                                fill="#000000"
                                className="-mt-1 h-7 w-7 text-gray-700 "
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                            >
                                <path d="M 16 4 C 9.3844276 4 4 9.3844276 4 16 C 4 22.615572 9.3844276 28 16 28 C 22.615572 28 28 22.615572 28 16 C 28 9.3844276 22.615572 4 16 4 z M 16 6 C 21.534692 6 26 10.465308 26 16 C 26 21.027386 22.311682 25.161277 17.488281 25.878906 L 17.488281 18.916016 L 20.335938 18.916016 L 20.783203 16.023438 L 17.488281 16.023438 L 17.488281 14.443359 C 17.488281 13.242359 17.882859 12.175781 19.005859 12.175781 L 20.810547 12.175781 L 20.810547 9.6523438 C 20.493547 9.6093438 19.822688 9.515625 18.554688 9.515625 C 15.906688 9.515625 14.355469 10.913609 14.355469 14.099609 L 14.355469 16.023438 L 11.632812 16.023438 L 11.632812 18.916016 L 14.355469 18.916016 L 14.355469 25.853516 C 9.6088556 25.070647 6 20.973047 6 16 C 6 10.465308 10.465308 6 16 6 z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </PopupWrapper>

            <PopupWrapper toggleButton={toggle} setToggleButton={setToggle} title="Contact Support">
                <div className=" rounded-l-md bg-white">
                    <div className="p-7">
                        <h4 className="text-lg font-semibold text-gray-800">Need some help?</h4>
                        <p className="mt-1 mb-7 text-sm text-gray-600">
                            If you have any issues ordering or would like to ask a question pick an
                            option below.
                        </p>
                        <div className="grid gap-4">
                            <a
                                href="mailto:help@parallelmeals.ie"
                                target="_blank"
                                className="w-full rounded-md bg-gray-50 p-3 text-center text-sm font-semibold text-black "
                                rel="noreferrer"
                            >
                                Email us
                            </a>
                            <a
                                href="https://www.facebook.com/diarmaid.healy.31"
                                target="_blank"
                                className="w-full rounded-md bg-gray-50 p-3 text-center text-sm font-semibold text-black "
                                rel="noreferrer"
                            >
                                Find us on Facebook
                            </a>
                            <a
                                href="https://www.instagram.com/parallelmeals/"
                                target="_blank"
                                className="w-full rounded-md bg-gray-50 p-3 text-center text-sm font-semibold text-black "
                                rel="noreferrer"
                            >
                                Find us on Instagram
                            </a>
                        </div>
                    </div>
                </div>
            </PopupWrapper>
        </div>
    );
}
